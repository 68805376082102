import {
  ActionColumnKey, ArrayTypes, DateFormat, DateTimeFormat, IdColumnKey, showInDrawerHasDetailFieldTypes,
} from '@config/base';
import dayjs from "dayjs";

import {
  DynamicSelectValues,
  EnumMetaProps,
  ObjectMetaProps,
  ObjectValues,
  TableMetaProps, ColumnType, ObjectColumns, SaveOperation, RecordProps, TableMode
} from '@props/RecordProps';
import {
  fetchCurrentValues,
  fetchDomainMeta,
  fetchEnumOptions,
  fetchSelectOptions,
} from '@utils/FetchUtils';
import { transferColumnMeta } from "@kernel/DisplayComponentsMapping";
import operationColumn from "../form/list/OperationColumnMeta";
import { humanReadableTitle } from '@utils/StringUtils';
import { getLabelToDisplay, getRawDomainName } from "@utils/ObjectUtils";

import { DynamicMenuDomainName, FormTypeDomainName } from '@config/domain';
import i18next from "i18next";
import i18n from "@config/i18n";
import { Dispatch } from "react";
import { DataProps } from "./hooks/useData";

/** 表格的最大宽度, 默认设置为用户浏览器的宽度减去 355 (经验值) */
export const tableColumnMaxWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 355;

/** 默认的列宽度 */
export const columnsTypeDefaultWidth = 100;

/** 不同的字段路径对应的列宽度, 具有最高优先级 */
// TODO 将这里的配置修改为在后台数据存储
export const columnsFullPathWidth = {
  "DynamicLogic.name": 350,
  "DynamicLogic.description": 350,
  "tech_muyan_dynamic_DynamicLogicRevision.name": 350,
  "tech_muyan_dynamic_DynamicLogicRevision.description": 350,
  "DynamicLogicTrash.name": 350,
  "DynamicLogicTrash.description": 350,
  "RequestMap.url": 500,
} as {
  [propName: string]: number;
};

/** 不同的数据类型对应的列宽度，优先级低于 columnsFullPathWidth 高于默认的 */
export const columnsTypeWidth = {
  "id": 70,
  "array": 70,
  "integer": 100,
  "df_multiple": 100,
  "boolean": 70,
  "date": 150,
  "datetime": 200,
  "text": 150,
  "string": 150,
  "object": 180,
  "logicType": 200,
  "status": 160,
  "enableRoles": 200,
  [FormTypeDomainName]: 150,
  "attachments": 70,
  "attachment": 70,
  "document": 70,
  "icon": 200,
} as {
    [type in (ColumnType | string)]: number;
  };

/** 是否是 Action Column */
export const isActionColumn = (key: string): boolean => {
  return key === ActionColumnKey;
};

/**
 * 某列是否禁用表格列头上的搜索控件，
 * 处理一些当前搜索不生效的列，如 Dynamic Menu 下的 form 字段
 */
export const isDisableSearchColumn = (domainName: string, column: TableMetaProps): boolean => {
  const { key, disableSearch } = column;
  return disableSearch || (domainName === 'DynamicMenu' && key === 'form') ||
    (domainName === DynamicMenuDomainName && key === 'form');
};

/**
 * Sort table columns
 * As per antd request, left fixed columns should be on head of columns array
 * (https://github.com/ant-design/ant-design/issues/22184)
 * So table columns is sorted, to move id and action column to head
 * Also action column is move ahead of id column
 */
export const sortTableColumns = (a: TableMetaProps, b: TableMetaProps): number => {
  if (a.key === IdColumnKey && b.key === ActionColumnKey) {
    return 1;
  }
  if (a.key === IdColumnKey || a.key === ActionColumnKey) {
    return -1;
  }
  return 1;
};

/**
 * Whether a column has detail panel display on right side
 */
export const hasDetailPanel = (column: TableMetaProps): boolean => {
  return (column.hasDetailPanel === true) || (column.extInfo?.hasDetailPanel === true);
};

/**
 * Whether a column type is a enum?
 * @param type type from backend domain meta API
 */
export function isEnumColumn(type: string): boolean {
  return type != null && type.includes("_enums_");
}

/**
 * Whether a column type is a dynamic field with options?
 * @param type type from backend domain meta API
 */
export function isDynamicSelectColumn(type: string): boolean {
  return ["df_tags", "df_multiple", "df_single", "df_radio", "df_checkbox"].includes(type);
}

/**
 * Whether a type is date or date time type
 * @param type type from backend domain meta API
 */
export function isDateOrDateTimeType(type: string): boolean {
  return isDateType(type) || isDateTimeType(type);
}

/**
 * Whether a type is date type
 * @param type type from backend domain meta API
 */
export function isDateType(type: string): boolean {
  return type != null && ["date"].includes(type);
}

/**
 * Whether a type is date time type
 * @param type type from backend domain meta API
 */
export function isDateTimeType(type: string): boolean {
  return type != null && ["datetime"].includes(type);
}

/**
 * Whether an value is object in backend,
 * Here only checks if the value is an javascript object
 * And contains an attribute of name "id"
 * @param value the value to check
 */
export function isNonEmptyObjectValue(value: string): boolean {
  return (value != null) && (typeof value === 'object') && ('id' in value);
}

/**
 * Whether an value is an non empty array of numbers
 * @param value value to check
 */
export function isNonEmptyNumberArrayValue(value: string): boolean {
  return (value != null) && Array.isArray(value) &&
    value.length > 0 && (typeof value[0] == "number");
}

/**
 * Whether an type is object in backend
 * For types start with tech_muyan we assume they are of type object
 * @param type type name
 */
export function isObjectType(type: string): boolean {
  if (type == null) {
    return false;
  }
  if (type.startsWith("DYNAMIC-")) {
    return true;
  }
  if (getRawDomainName(type) !== type) {
    return isObjectType(getRawDomainName(type));
  }
  return ((type != null) && (type.startsWith("tech_muyan")))
    && (!type.includes("_enums_"));
}

/*
 * Whether an type is one to many related's many side?
 * @param type type name
 */
export function isArrayType(type: string): boolean {
  return ArrayTypes.includes(type);
}

/**
 * Whether a column is an object column
 * @param tableMeta The column meta data to check
 * @param objIds List of object ids to check against
 */
export function isObjectColumn(tableMeta: TableMetaProps, objIds: ObjectColumns): boolean {
  return Object.keys(objIds).includes(tableMeta.key) &&
    !['file', 'array', 'tags', 'roles'].includes(tableMeta.type);
}

/**
 * Get column translation key, namespace for column translation keys is field
 * @param domainName name of the domain, can be short or long(package_name_ClassName format)
 * @param columnKey key of the column
 */
export function getColumnTransKey(domainName: string, columnKey: string): string {
  return `field:${domainName.substring(domainName.lastIndexOf("_") + 1)}.${columnKey}`;
}

/**
 * Decide whether a column is a dynamic column
 * @param columnKey key of the column
 * @returns true if the column is a dynamic column, otherwise false
 */
export function isDynamicField(columnKey: string): boolean {
  return columnKey?.startsWith("df_");
}

export const readOnlyFieldValue = (col: TableMetaProps,
  fieldValue?: string | object | Array<unknown> | boolean | number): string => {
  if (fieldValue == null) {
    return "";
  }
  const { type } = col;
  if (type === "datetime") {
    return dayjs().format(DateTimeFormat);
  }
  if (type === "date") {
    return dayjs().format(DateFormat);
  }
  return fieldValue?.toString();
};

/**
 * 判断某列是否用前台的默认翻译
 * @param col 列的元数据
 */
export const shouldUseFrontendTrans = (col: TableMetaProps): boolean => {
  return col.title == null || col.title.replaceAll(" ", "").toLowerCase() === col.key.toLowerCase();
};

/**
 * 判断某个 form 的所有字段中，有无名为 children 的字段
 * @param meta 所有的 form 的 meta 列表
 */
export const hasChildrenField = (meta: Array<TableMetaProps>): boolean => {
  const childrenField = meta.find(f => f.key === 'children');
  return (childrenField != null);
};

/**
 * 根据元数据和当前的操作判断一列是不是应该显示 detail 面板
 * 注意: 对于 create 模式，array 类型的字段显示 detail panel，因 create 时会隐藏 array 类型的字段
 * @param mode 当前的操作模式
 * @param column 待判断的列
 * @returns 如果应该显示 detail panel 返回 true 否则返回 false
*/
export const shouldDisplayDetailPanel = (mode: SaveOperation, column: TableMetaProps): boolean => {
  const isEditMode = (mode === 'edit');
  const isCreateMode = (mode === 'create');
  const hasDetailPanel = (column.hasDetailPanel === true || column.extInfo?.hasDetailPanel === true);
  const hasDetailFieldEdit: boolean = hasDetailPanel && isEditMode;
  const hasNonArrayHasDetailFieldCreate: boolean = !isArrayType(column.type) && isCreateMode && hasDetailPanel;
  const result = hasDetailFieldEdit || hasNonArrayHasDetailFieldCreate;
  return result;
};

/**
 * 在抽屉中显示时，判断某个字段是否需要隐藏
 * @param col 待判断的列的元数据
 * @param displayMode 显示模式，对于抽屉中显示，该参数值为 detail-drawer
*/
export const hideInDrawerMode = (col: TableMetaProps, displayMode?: string): boolean => {
  return (!showInDrawerHasDetailFieldTypes.includes(col.type)
    && displayMode === 'detail-drawer'
    && hasDetailPanel(col)) || !hasDetailPanel(col);
};

export interface DataAndPossibleObjectIdsProps {
  data: Array<RecordProps>;
  possibleObjectIds: ObjectColumns;
}

export const summaryField = (column: TableMetaProps): string | undefined => {
  return column.extInfo?.summaryField;
};

/**
 * Caculate possible object column id set from list of array and retun it as a list
 * @param dataArray List of data array to check against
 * @return the original dataArray and set of possible object ids
 */
export const calcPossibleObjectColumnIds = (dataArray: Array<RecordProps>): DataAndPossibleObjectIdsProps => {
  const c = {} as ObjectColumns;
  dataArray.forEach(row => {
    const entries = Object.entries(row);
    for (const [key, value] of entries) {
      if (isNonEmptyObjectValue(value)) {
        const myId = value.id;
        const current = (key in c) ? c[key] : new Set<number>();
        current.add(myId);
        c[key] = current;
      } else if (isNonEmptyNumberArrayValue(value)) {
        const current = (key in c) ? c[key] : new Set<number>();
        value.forEach((v: number) => current.add(v));
        c[key] = current;
      }
    }
  });
  return {
    data: dataArray,
    possibleObjectIds: c
  };
};

export const refreshMetas = (props: DataAndPossibleObjectIdsProps,
  refreshListFormId: number,
  domainName: string,
  dataDispatch: Dispatch<{ type: "set"; payload: DataProps; }>,
  setLoading: (loading: boolean) => void,
  setColumns: (columns: Array<TableMetaProps>) => void,
  tableMode: TableMode,
  fetchDataWrap: () => void,
  isFinderMode: boolean,
  zIndex: number,
  ownerId?: number,
  ownerClass?: string): void => {
  const { possibleObjectIds, data, } = props;

  const transferFilterSet = (domainMeta: Array<TableMetaProps>,
    enumValues: { [id: string]: EnumMetaProps[] },
    objectValues: ObjectValues,
    domainName: string
  ): void => {
    const meta = transferColumnMeta(
      domainMeta, enumValues, objectValues, domainName, tableMode, zIndex
    );
    const filtered = meta.filter(
      m => (m.clientSideShow !== false) && (m.display !== false)
    );
    filtered.forEach(column => {
      const { key } = column;
      if (!isActionColumn(key)) {
        calcColumnTitle(domainName, column);
      }
    });
    // Backup the original render, columns' render will be modified to
    // highlight match part of the filter if user do client side filter, here
    // we backup the origial render, to be used when user clear the filter
    filtered.forEach(column => column.backupRender = column.render);
    filtered.sort(sortTableColumns);
    setColumns(filtered);
    setLoading(false);
  };

  fetchDomainMeta(domainName, refreshListFormId).then((domainMeta) => {
    if (!isFinderMode) {
      domainMeta.unshift(operationColumn({
        domainName,
        ownerClass,
        data,
        ownerId,
        tableMode,
        fetchDataCallback: fetchDataWrap,
        zIndex,
        deleteCallback: (id: number) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const newData = data.filter((d: RecordProps) => d.id !== id);
          dataDispatch({ type: 'set', payload: { data: newData } });
        },
      }));
    }
    const localEnumValues = {} as {
      [key: string]: Array<EnumMetaProps>;
    };
    const localObjValues = {} as ObjectValues;
    const localDynamicSelectValues = {} as DynamicSelectValues;
    const hasEnum = domainMeta.some(m => isEnumColumn(m.type));
    const hasDynamicSelect = domainMeta.some(m => isDynamicSelectColumn(m.type));
    const hasObject = (Object.keys(possibleObjectIds).length > 0);
    if (hasEnum || hasObject || hasDynamicSelect) {
      const enumOrObjectOrDynamicSelectField = domainMeta
        .filter(m => (isEnumColumn(m.type) || isObjectColumn(m, possibleObjectIds) || isDynamicSelectColumn(m.type)));
      const objectTypeSet = new Set();
      enumOrObjectOrDynamicSelectField
        .filter(c => isObjectColumn(c, possibleObjectIds))
        .filter(c => !isDynamicSelectColumn(c.type))
        .forEach(c => objectTypeSet.add(c.type));

      // FIXME If there's  multiple enum with same type exists on one object,
      // there will be issue
      const enumTypeSet = new Set();
      enumOrObjectOrDynamicSelectField
        .filter(c => isEnumColumn(c.type))
        .forEach(c => enumTypeSet.add(c.type));

      const dynamicSelectSet = new Set();
      enumOrObjectOrDynamicSelectField
        .filter(c => isDynamicSelectColumn(c.type))
        .forEach(c => dynamicSelectSet.add(c.type));

      if (enumOrObjectOrDynamicSelectField.length > 0) {
        const enumSetSize = enumTypeSet.size;
        const dynamicSelectSetSize = dynamicSelectSet.size;
        const objectSetSize = objectTypeSet.size;
        const allFetched = (): boolean => {
          return (Object.keys(localEnumValues).length === enumSetSize &&
            (Object.keys(localDynamicSelectValues).length === dynamicSelectSetSize) &&
            (Object.keys(localObjValues).length === objectSetSize));
        };
        enumOrObjectOrDynamicSelectField.forEach((m) => {
          if (isEnumColumn(m.type)) {
            fetchEnumOptions(m.type).then(json => {
              localEnumValues[m.key] = (localEnumValues[m.key] != null) ?
                localEnumValues[m.key].concat(json) : json;
              if (allFetched()) {
                transferFilterSet(domainMeta, localEnumValues, localObjValues, domainName);
              }
              dataDispatch({ type: 'set', payload: { enumValues: localEnumValues } });
            }).catch(e => console.error(`Failed to fetch enum options for ${m.type}: ${e}`));
          } else if (isDynamicSelectColumn(m.type)) {
            if (localDynamicSelectValues[m.key] == null) {
              fetchSelectOptions(m.key).then(options => {
                localDynamicSelectValues[m.key] = options.map(opt => {
                  return {
                    value: opt,
                    label: opt,
                  };
                });
                if (allFetched()) {
                  transferFilterSet(domainMeta, localEnumValues, localObjValues, domainName);
                }
                dataDispatch({ type: 'set', payload: { dynamicSelectValues: localDynamicSelectValues } });
              }).catch((e: Error) => console.error(`Failed to fetch select options for ${m.key}: ${e}`));
            } else {
              if (allFetched()) {
                transferFilterSet(domainMeta, localEnumValues, localObjValues, domainName);
              }
            }
          } else if (isObjectColumn(m, possibleObjectIds)) {
            const objectIds: Array<number> = [...possibleObjectIds[m.key]].filter(id => id != null);
            fetchCurrentValues(m.elementDomain ?? m.type, objectIds).then(json => {
              const data = [] as Array<ObjectMetaProps>;
              for (let i = 0; i < json.length; i++) {
                const value = json[i];
                const displayVal = getLabelToDisplay(value, m.labelField);
                const appendData = {
                  value: value.id,
                  label: displayVal
                };
                data.push(appendData);
              }
              localObjValues[m.type] = (localObjValues[m.type] != null) ?
                localObjValues[m.type].concat(data) : data;
              if (allFetched()) {
                transferFilterSet(domainMeta, localEnumValues, localObjValues, domainName);
              }
              dataDispatch({ type: 'set', payload: { objectValues: localObjValues } });
            }).catch((e: Error) => console.error(`Failed to get current values ${m.type} ${objectIds}: ${e}`));
          }
        });
      } else {
        transferFilterSet(domainMeta, {}, {}, domainName);
      }
    } else {
      transferFilterSet(domainMeta, {}, {}, domainName);
    }
  }).catch((error: Error) => console.error(`Error getting domain ${domainName} meta`, error));
  // The useEffect depends on enumValueLabelMappings, domainName and operationColumn
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export const sortByDisplaySequence = <T extends { displaySequence?: number }>(a: T, b: T): number =>
  (a.displaySequence ?? 0) - (b.displaySequence ?? 0);

export const calcColumnTitle = (domainName: string, column: TableMetaProps): void => {
  const { key, title } = column;
  const transKey = getColumnTransKey(domainName, key);
  const predict = (isDynamicField(key) || !i18next.exists(transKey) || !shouldUseFrontendTrans(column));
  column["title"] = predict ? humanReadableTitle(title) : i18n.t(transKey);
};
