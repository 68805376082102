import { ActionRedirectCountdown } from '@config/base';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getRedirectConfig, isInternalLink } from './RedirectConfigs';
import { removePackagePart } from "@utils/StringUtils";
import { useLocation } from 'react-router-dom';
import modalPropsBuilder from "../../form/ModalPropsBuilder";

const RedirectComponent = (props: {
  redirect?: string,
  forMultiple: boolean,
  fetchDataCallback?: () => void;
  // zIndex 是用于让调用 redirectComponent 的对象指定二层弹窗的 zIndex,
  // 确保在 redirect component 中弹出的 modal 在基础窗口或 modal 之上
  zIndex: number;
  showText?: boolean;
  autoRedirectCountdown?: number;
  ownerClass?: string;
  ownerId?: number;
  columnNameInOwnerClass?: string;
  hasRelateObjectField?: boolean;
}): ReactElement => {
  const {
    redirect, forMultiple, fetchDataCallback, zIndex, showText, autoRedirectCountdown,
    ownerClass, ownerId, columnNameInOwnerClass, hasRelateObjectField
  } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const currentUrl = location.pathname;

  const [initRedirected, setInitRedirected] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(autoRedirectCountdown ?? ActionRedirectCountdown);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [triggerSave, setTriggerSave] = useState<number | false>(false);

  const redirectConfig = getRedirectConfig(redirect);
  const {
    mode, domainName, domainId, formId, wizardId,
    columnName, sourceId, targetId, queryParameters
  } = redirectConfig.parseParameters(redirect);
  const isShowRedirect = (redirectConfig.mode === 'show');
  const isCreateRedirect = (redirectConfig.mode === 'create');
  /** 查看详情的 Modal 的只读状态，在查看详情的 Modal 中，可以点击编辑按钮，将其状态修改为编辑状态 */
  const [detailModalReadonly, setDetailModalReadonly] = useState<boolean>(isShowRedirect);

  const redirectNotEmpty = (redirect != null && redirect !== '');
  // 只有对非 multiple 类型的 action 才会启用 auto redirect
  const autoRedirect = (redirectNotEmpty && !forMultiple);
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [redirect, countdown]);

  if (autoRedirect && redirect != null) {
    if (!initRedirected) {
      setTimeout(() => {
        setShowModal(true);
        setInitRedirected(true);
      }, (ActionRedirectCountdown) * 1000);
    }
  }
  const withoutPackageDomainName = removePackagePart(domainName);
  const modalTitle = (domainName != null && columnName != null && sourceId != null && targetId != null) ?
    t('Compare revision modal title', {
      domainName: t(`domainTitle:${withoutPackageDomainName}`),
      columnName: t(`field:${withoutPackageDomainName}.${columnName}`),
      sourceId, targetId
    }) : ((domainName != null && columnName != null && targetId != null) ?
      t("Compare history revision with latest version") : undefined);

  const modalzIndex = zIndex;
  const switchEditable = (editable: boolean): void => {
    setDetailModalReadonly(!editable);
  };
  const readonly = (isShowRedirect && detailModalReadonly) ||
    (!isShowRedirect && detailModalReadonly);

  // modalProps 只用于 create, update, list 和 detail
  // 不用于 wizard
  const modalProps = modalPropsBuilder({
    mode, domainName, t,
    onCancel: () => {
      setShowModal(false);
      fetchDataCallback?.();
    },
    open: showModal,
    isValid: true,
    zIndex: modalzIndex,
    id: domainId,
    readonly,
    hasRelateObjectField,
    modalTitle,
    refererUrl: currentUrl,
    switchEditable,
  });
  const content = showModal ? redirectConfig.getContent({
    domainName, domainId, redirect, triggerSave, formId, wizardId,
    sourceId, targetId, columnName,
    callback: () => setTriggerSave(false), zIndex,
    onCancelCallback: () => {
      setShowModal(false);
      fetchDataCallback?.();
    }, ownerClass, ownerId, columnNameInOwnerClass, queryParameters,
    switchEditable, readonly
  }) : (<></>);

  const externalElem = isInternalLink(redirect) ? (<></>) : (<Modal
    className="link-render-modal"
    //zIndex should be higher than 1050, which is zIndex of dropdown menu
    style={{ top: "1.5rem", width: "95%", zIndex: modalzIndex }}
    footer={<Button onClick={() => {
      setShowModal(false);
      fetchDataCallback?.();
    }}>{t('Close')}</Button>}
    open={showModal}
    centered={false}
    title={``}
    onCancel={() => {
      setShowModal(false);
      fetchDataCallback?.();
    }}
    closable={false}
    maskClosable={true}
    zIndex={modalzIndex}
    destroyOnClose={true}
    width="95%"
    onOk={() => {
      setShowModal(false);
      fetchDataCallback?.();
    }}
  >
    {content}
  </Modal>);
  const modalWidth = (isCreateRedirect || (hasRelateObjectField === false)) ? "60%" : "90%";
  const internalElem = (isInternalLink(redirect)) ?
    ((redirectConfig.wrapInModal) ?
      (<Modal
        {...modalProps}
        destroyOnClose={true}
        width={modalWidth}
        style={{ minWidth: "650px" }}
      >
        {content}
      </Modal>
      ) : content) : (<></>);
  const linkElem = isInternalLink(redirect) ? internalElem : externalElem;
  const dt = (showText !== false);

  return (
    <span>
      <span
        role="link"
        className="link-span"
        onClick={() => {
          setInitRedirected(true);
          setShowModal(true);
        }}>
        {dt && autoRedirect && countdown > 0 && <><LoadingOutlined /> {t('Opening')} </>}
        {dt && countdown <= 0 && <>{t('Redirected to')} <Tag className='redirect-tag'> {redirect} </Tag></>}
        {dt && autoRedirect && countdown > 0 && <> <br /> in {countdown} seconds... </>}
      </span>
      {(autoRedirect && countdown <= 0) && linkElem}
    </span>
  );
};

export default RedirectComponent;
