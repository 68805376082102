import React, { ReactElement } from 'react';
import { SaveOperation, SwitchEditableProps } from '@props/RecordProps';
import RevisionCompare from "../revisionCompare/RevisionCompare";
import { getParamsFromUrl } from "@utils/StringUtils";
import { MessageDomainName } from '@config/domain';
import MasterDetailListComponent from '../../form/masterDetailList/MasterDetailListComponent';
import WizardAction from '../../form/wizard/WizardAction';
import { CreateAction } from '../../form/create';
import ListComponent from '../../form/list/ListComponent';
import { UpdateAction } from '../../form/update';

export type RedirectType = 'update' | 'create' | 'show' | 'list' | 'wizard' | 'external' | 'diff' | 'inbox';
export type RedirectMode = SaveOperation | "external" | "diff" | "inbox";

//FIXME Group all parameters based on mode into different sub properties
export interface LinkProps extends SwitchEditableProps {
  domainName: string;
  domainId?: number;
  redirect?: string;
  triggerSave?: false | number;
  formId?: number;
  callback?: () => void;
  wizardId?: number;
  zIndex: number;
  onCancelCallback?: () => void;
  ownerClass?: string;
  ownerId?: number;
  columnNameInOwnerClass?: string;
  columnName?: string;
  targetId?: number;
  sourceId?: number;
  queryParameters?: Record<string, string | number>;
}

//FIXME Group all parameters based on mode into different sub properties
export interface ParsedRedirectParameters {
  mode: RedirectMode,
  domainName: string;
  domainId?: number;
  formId?: number;
  wizardId?: number;
  redirect?: string;
  columnName?: string;
  targetId?: number;
  sourceId?: number;
  queryParameters?: Record<string, string | number>;
}

export type RedirectConfigType = {
  mode: RedirectType;
  urlRegex: string,
  getContent: (props: LinkProps) => ReactElement;
  parseParameters: (redirect?: string) => ParsedRedirectParameters;
  wrapInModal: boolean;
};

export const isRedirectOfType = (
  type: RedirectConfigType, redirect?: string
): boolean => {
  if (redirect == null) {
    return false;
  }
  const regex = new RegExp(type.urlRegex);
  const isRelativeUrl = redirect.startsWith("/");
  const urlStart = `${window.location.protocol}//${window.location.host}`;
  const isAbsoluteUrl = (!isRelativeUrl) && redirect.startsWith(urlStart);
  return (isAbsoluteUrl || isRelativeUrl) && regex.test(redirect);
};

export const getRedirectConfig = (redirect?: string): RedirectConfigType => (
  RedirectConfigures.filter(c => isRedirectOfType(c, redirect))?.[0]
);

export const isInternalLink = (redirect?: string): boolean => (
  getRedirectConfig(redirect)?.mode !== 'external'
);

export const RedirectConfigures: Array<RedirectConfigType> = [
  {
    mode: 'inbox',
    urlRegex: '/inbox(/[0-9]*)?',
    wrapInModal: true,
    getContent: (props: LinkProps): ReactElement => {
      const { domainId } = props;
      return (<MasterDetailListComponent
                domainName={MessageDomainName}
                zIndex={0}
                initSelectedId={domainId}
              />);
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      const urlParts = redirect?.split("/");
      const domainId = (urlParts?.length ?? 0) > 2 ? urlParts?.[2] : undefined;
      return {
        domainId: (domainId != null) ? parseInt(domainId) : undefined,
        mode: "inbox",
        domainName: MessageDomainName
      } as ParsedRedirectParameters;
    }
  },
  {
    mode: 'diff',
    urlRegex: '/diff/[a-zA-Z_]+/[0-9]+/[a-zA-Z]+$',
    wrapInModal: true,
    getContent: (props: LinkProps): ReactElement => {
      const { targetId, columnName, domainName } = props;
      return (<RevisionCompare
        targetId={targetId ?? -1}
        columnName={columnName ?? ""}
        domainName={domainName}
      />);
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      const parts = redirect?.split("/");
      return {
        mode: "diff",
        columnName: parts?.[parts.length - 1] ?? "",
        targetId: parseInt(parts?.[parts.length - 2] ?? ""),
        domainName: parts?.[parts.length - 3] ?? "",
      };
    }
  },
  {
    mode: 'diff',
    urlRegex: '/diff/[a-zA-Z_]+/[0-9]+/[0-9]+/[a-zA-Z]+$',
    wrapInModal: true,
    getContent: (props: LinkProps): ReactElement => {
      const { sourceId, targetId, columnName, domainName } = props;
      return (<RevisionCompare
        sourceId={sourceId ?? undefined}
        targetId={targetId ?? -1}
        columnName={columnName ?? ""}
        domainName={domainName}
      />);
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      const parts = redirect?.split("/");
      return {
        mode: "diff",
        columnName: parts?.[parts.length - 1] ?? "",
        targetId: parseInt(parts?.[parts.length - 2] ?? ""),
        sourceId: parseInt(parts?.[parts.length - 3] ?? ""),
        domainName: parts?.[parts.length - 4] ?? "",
      };
    }
  },
  {
    mode: 'wizard',
    //TODO Should change to same as current default wizard URL format
    // --> wizard/384
    // --> current its of format: 384/DynamicConfig/wizard?df_xxxx=yyy
    urlRegex: '.*[0-9]+/[a-zA-Z]+/wizard.*$',
    wrapInModal: false,
    getContent: (props: LinkProps): ReactElement => {
      const { wizardId, zIndex, onCancelCallback, queryParameters } = props;
      return (<WizardAction
        wizardId={wizardId ?? -1}
        stepId={-1}
        zIndex={zIndex}
        visibleSeed={Math.random()}
        onCancelCallback={onCancelCallback}
        queryParameters={queryParameters}
      />);
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      const parts = redirect?.split("/");
      const queryParameters = getParamsFromUrl(redirect ?? "");
      return {
        mode: "wizard",
        domainName: parts?.[parts.length - 2] ?? "",
        wizardId: parseInt(parts?.[parts?.length - 3] ?? ""),
        queryParameters,
      };
    }
  }, {
    mode: 'create',
    urlRegex: '.*[a-zA-Z]+/create$',
    wrapInModal: false,
    getContent: (props: LinkProps): ReactElement => {
      const {
        domainName, triggerSave, callback, zIndex, ownerClass, ownerId, columnNameInOwnerClass,
        onCancelCallback, queryParameters
      } = props;
      return <CreateAction
        initShowCreateModal={true}
        domainName={domainName}
        triggerSave={triggerSave}
        callback={callback}
        zIndex={zIndex}
        ownerClass={ownerClass}
        ownerId={ownerId}
        columnNameInOwnerClass={columnNameInOwnerClass}
        showIconAndText={false}
        onCancelCallback={onCancelCallback}
        queryParameters={queryParameters}
      />;
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      const parts = redirect?.split("/");
      return {
        mode: "create",
        domainName: parts?.[parts?.length - 2] ?? "",
      };
    }
  }, {
    mode: 'list',
    urlRegex: '.*[a-zA-Z]+/list$',
    wrapInModal: true,
    getContent: (props: LinkProps): ReactElement => {
      const { domainName, zIndex, ownerClass, ownerId } = props;
      return <ListComponent
        domainName={domainName}
        tableMode="table-list"
        inline={false}
        zIndex={zIndex}
        ownerClass={ownerClass}
        ownerId={ownerId}
      />;
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      const parts = redirect?.split("/");
      return {
        mode: "list",
        domainName: parts?.[parts?.length - 2] ?? "",
      };
    }
  }, {
    mode: 'show',
    urlRegex: '.*[a-zA-Z_]+/[0-9]+(/show)?$',
    wrapInModal: false,
    getContent: (props: LinkProps): ReactElement => {
      const {
        domainName,
        domainId,
        callback,
        zIndex,
        ownerClass,
        ownerId,
        onCancelCallback,
        readonly,
        switchEditable
      } = props;
      return <UpdateAction
        initShowEditModal={true}
        domainName={domainName}
        id={domainId ?? -1}
        readonly={readonly}
        callback={callback}
        zIndex={zIndex}
        ownerClass={ownerClass}
        ownerId={ownerId}
        showIconAndText={false}
        onCancelCallback={onCancelCallback}
        switchEditable={switchEditable}
      />;
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      const parts = redirect?.split("/");
      //兼容 /xxx/123/show 和 /xxx/123 两种 URL 形式
      if (parts?.length === 3) {
        return {
          mode: "edit",
          domainName: parts?.[parts.length - 2] ?? "",
          domainId: parseInt(parts?.[parts?.length - 1] ?? "")
        };
      }
      return {
        mode: "edit",
        domainName: parts?.[parts.length - 3] ?? "",
        domainId: parseInt(parts?.[parts?.length - 2] ?? "")
      };
    }
  }, {
    mode: 'update',
    urlRegex: '.*[a-zA-Z_]+/[0-9]+/update$',
    wrapInModal: false,
    getContent: (props: LinkProps): ReactElement => {
      const {
        domainName,
        triggerSave,
        callback,
        zIndex,
        domainId,
        ownerClass,
        ownerId,
        onCancelCallback,
        readonly,
        switchEditable
      } = props;
      return <UpdateAction
        initShowEditModal={true}
        domainName={domainName}
        id={domainId ?? -1}
        triggerSave={triggerSave}
        callback={callback}
        zIndex={zIndex}
        ownerClass={ownerClass}
        ownerId={ownerId}
        showIconAndText={false}
        onCancelCallback={onCancelCallback}
        readonly={readonly}
        switchEditable={switchEditable}
      />;
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      const parts = redirect?.split("/");
      return {
        mode: "edit",
        domainName: parts?.[parts.length - 3] ?? "",
        domainId: parseInt(parts?.[parts?.length - 2] ?? "")
      };
    }
  },
  {
    //ATTENTION: external link should be last element of the array
    //since urlRegex is simplifed to [.*] and will match any given URL
    mode: 'external',
    urlRegex: '.*',
    wrapInModal: true,
    getContent: (props: LinkProps): ReactElement => {
      const { redirect } = props;
      return (<iframe
        src={redirect}
        style={{ height: "85vh", width: "100%" }}
        title={redirect}
      />);
    },
    parseParameters: (redirect?: string): ParsedRedirectParameters => {
      return {
        redirect,
        mode: "external",
        domainName: ""
      };
    }
  },
];
