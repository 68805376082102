import React from "react";
import { ReactElement } from "react";
import { CustomIcon, PrimaryColor } from '@config/base';
import { Popover } from "antd";
import { CellComponentDisplayPage } from "@props/RecordProps";
import { Suspense } from "react";

const ReactJson = React.lazy(() => import('react-json-view'));

const JsonCell = (props: {
  page: CellComponentDisplayPage,
  text: string;
}): ReactElement => {
  const { page, text } = props;
  if (['', '{}', '{ }'].includes(text) || text == null) {
    return <></>;
  }
  return (page === 'DISPLAY') ? <Suspense fallback={<div />}><ReactJson
    name={false}
    src={JSON.parse(text)}
    theme="rjv-default"
    collapsed={false}
    displayDataTypes={true}
    iconStyle={"circle"}
    indentWidth={4}
  /></Suspense> : <Popover
    trigger="click"
    content={
      <Suspense fallback={<div />}>
        <ReactJson
          //Don't display first level element as a separate level
          name={false}
          src={JSON.parse(text)}
          theme="rjv-default"
          collapsed={false}
          displayObjectSize={false}
          displayDataTypes={false}
          iconStyle={"circle"}
          indentWidth={4}
        />
      </Suspense>
    }
    title={undefined}
  >
    <CustomIcon
      type="icon-json1"
      style={{ fontSize: "200%", color: PrimaryColor }}
    />
  </Popover>;
};

export default JsonCell;
