import React, { ChangeEvent, ReactElement, Suspense } from "react";
import { DatePicker, Input, InputNumber, Select, Switch } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { EditableComponentDefaultStyle, FileFieldType } from '@config/base';
import { TableMode } from "@props/RecordProps";
import { getReadOnlyClass } from "@utils/ComponentUtils";
import { hasDetailPanel, isObjectType } from "@utils/ColumnsUtils";
import {
  EditableComponentRenderFunction,
  EditableControllerProps
} from "./ComponentsConfig";
import { SearchNoDataFoundText, SearchPlaceholderText } from "@utils/Constants";

import { SingleImage, SuffixIcon, FileOperator, CommentsIcon } from '../components';

import { HasDetailFieldPlaceholderCell } from "../form/cells";

import {
  Percentage, Currency, DynamicSelect, DynamicCheckboxAndRadio,
  EnumSelect, ObjectSelect, HttpMethodSelect, StaticFieldSelect,
  RangedSeriesInput, LineChart, TreeSelect
} from '../form/fields';
import { IconSelect } from "../components/icons";
import { getRawDomainName, isObject } from "@utils/ObjectUtils";
import SubTableForm from "../form/subTable/SubTableForm";
import ValueSelect from "../form/fields/ValueSelect";
import EntityAttributesComponentCell from "../form/entityAttributes/EntityAttributesComponentCell";
import { EntityAttributeValues } from "../form/entityAttributes/EntityAttributesUtils";
import FileOperatorCell from "../components/fileOperator/FileOperatorCell";
import { TableChart } from "../form/fields/TableChart";

const CodeEditor = React.lazy(() => import('../form/fields/CodeEditor'));

export const integerInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable } = props;
  const readOnlyClass = updatable ? "" : "readonly";
  return (<InputNumber
    className={`integer ${readOnlyClass}`}
    style={props.style ?? EditableComponentDefaultStyle}
    precision={0}
    readOnly={!updatable} />);
};

export const decimalInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable } = props;
  const readOnlyClass = updatable ? "" : "readonly";
  return (
    <InputNumber
      className={`decimal ${readOnlyClass}`}
      style={props.style ?? EditableComponentDefaultStyle}
      readOnly={!props.updatable} />
  );
};

export const stringInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable, fieldValue } = props;
  return (
    <Input
      className={`string ${getReadOnlyClass(updatable)}`}
      style={props.style ?? EditableComponentDefaultStyle}
      readOnly={!props.updatable}
      suffix={<SuffixIcon updatable={updatable} />}
      allowClear={true}
      value={fieldValue === undefined ? undefined : fieldValue} />);
};

export const passwordInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable } = props;
  return <Input.Password
    className={`password ${getReadOnlyClass(updatable)}`}
    style={props.style ?? EditableComponentDefaultStyle}
    readOnly={!props.updatable}
    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    allowClear={true} />;
};

export const datetimeInputComponentRender = (showTime: boolean):
  ((props: EditableControllerProps) => ReactElement) => {
  return (props: EditableControllerProps) => (
    <DatePicker
      className="datetime"
      style={props.style ?? EditableComponentDefaultStyle}
      dropdownClassName='datetime-dropdown'
      showTime={showTime}
      disabled={!props.updatable}
      allowClear={true}
    />
  );
};

export const codeInputComponentRender = (tableMode?: TableMode): EditableComponentRenderFunction => {
  return (tableMode === 'finder') ? (props: EditableControllerProps) => {
    return (
      <Input className={`string ${getReadOnlyClass(props.updatable)}`}
        style={props.style ?? EditableComponentDefaultStyle}
        readOnly={!props.updatable}
        suffix={<SuffixIcon updatable={props.updatable} />}
        allowClear={true}
      />);
  } : (props: EditableControllerProps) => {
    const {
      fieldValue, form, key, updatable, column, zIndex,
      record, onValuesChange,
    } = props;
    const { extInfo } = column;
    const { codeLanguage } = extInfo ?? {};
    return (hasDetailPanel(column)) ? (<HasDetailFieldPlaceholderCell
      {...props}
      columnKey={key}
    />) : (<Suspense fallback={<div />}><CodeEditor
        value={fieldValue}
        onChange={(val) => {
          form.setFieldsValue({ [key]: val });
          onValuesChange?.({ [key]: val }, form.getFieldsValue());
        }}
        name={key}
        updatable={updatable}
        width={updatable ? "95%" : "100%"}
        mode={codeLanguage}
        zIndex={zIndex}
        record={record}
    /></Suspense>);
  };
};

export const textInputComponentRender = (tableMode?: TableMode): EditableComponentRenderFunction => {
  return (tableMode === 'finder') ? (props: EditableControllerProps) => (
    <Input className={`string ${getReadOnlyClass(props.updatable)}`}
      style={props.style ?? EditableComponentDefaultStyle}
      readOnly={!props.updatable}
      suffix={<SuffixIcon updatable={props.updatable} />}
      allowClear={true}
    />) : (props: EditableControllerProps) => (
      <Input.TextArea
        className="text"
        style={props.style ?? EditableComponentDefaultStyle}
        readOnly={!props.updatable}
        defaultValue={props.fieldValue}
        autoSize={{ minRows: 3 }}
      />
    );
};

export const booleanInputComponentRender = (props: EditableControllerProps): ReactElement => (
  <Switch className="boolean"
    defaultChecked={Boolean(props.fieldValue)}
    disabled={!props.updatable}
  />
);

export const percentageInputComponentRender = (props: EditableControllerProps): ReactElement => (
  <Percentage
    className="percentage"
    style={props.style ?? EditableComponentDefaultStyle}
    readOnly={!props.updatable}
  />
);

export const currencyInputComponentRender = (props: EditableControllerProps): ReactElement => (
  <Currency
    className="currency"
    style={props.style ?? EditableComponentDefaultStyle}
    readOnly={!props.updatable}
  />
);

export const tagsInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable, fieldValue } = props;
  const displayVal = (fieldValue == null) ?
    "" : (typeof fieldValue === "string") ? fieldValue : fieldValue.join(",");
  return updatable ? (
    <Select
      className={`tags ${getReadOnlyClass(updatable)}`}
      mode="tags"
      style={props.style ?? EditableComponentDefaultStyle}
      tokenSeparators={[',']}
      disabled={!props.updatable}
      showSearch={true} />
  ) : <Input
      value={displayVal}
      style={props.style ?? EditableComponentDefaultStyle}
      readOnly={!updatable}
      suffix={<SuffixIcon updatable={updatable} />}
      className={getReadOnlyClass(updatable)}
    />;
};

export const tagListInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable, fieldValue } = props;
  console.log('fieldValue', fieldValue);
  return <Select
    className={`tags ${getReadOnlyClass(updatable)}`}
    mode="tags"
    style={props.style ?? EditableComponentDefaultStyle}
    disabled={!props.updatable}
    showSearch={true}
    defaultValue={fieldValue}
  />;
};

export const selectComponentRender = (
  mode?: "multiple" | "tags" | undefined
): ((props: EditableControllerProps) => ReactElement) => {
  return (props: EditableControllerProps) => {
    const { key, updatable, form, fieldValue } = props;
    return (<DynamicSelect
      mode={mode}
      dfKey={key}
      style={props.style ?? EditableComponentDefaultStyle}
      form={form}
      updatable={updatable}
      currentValue={fieldValue}
      onChange={(val: string | number): void => {
        form.setFieldsValue({
          [key]: val
        });
      }}
    />);
  };
};

export const radioInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { key, updatable, form, fieldValue } = props;

  return (<DynamicCheckboxAndRadio
    type="radio"
    dfKey={key}
    style={props.style ?? EditableComponentDefaultStyle}
    form={form}
    updatable={updatable}
    currentValue={fieldValue}
    onChange={(event: ChangeEvent<HTMLInputElement>): void => {
      form.setFieldsValue({
        [key]: event?.target?.value
      });
    }}
  />);
};

export const checkboxInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { key, updatable, form, fieldValue } = props;

  return (<DynamicCheckboxAndRadio
    type="checkbox"
    dfKey={key}
    style={props.style ?? EditableComponentDefaultStyle}
    form={form}
    updatable={updatable}
    currentValue={fieldValue}
    onChange={(val: string | number): void => {
      form.setFieldsValue({
        [key]: val
      });
    }}
  />);
};

export const enumInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable, column, type, form, key } = props;
  return (
    <EnumSelect
      style={props.style ?? EditableComponentDefaultStyle}
      updatable={updatable}
      type={column.enumType ?? type}
      placeholder={SearchPlaceholderText}
      notFoundContent={SearchNoDataFoundText}
      onSelect={(val: string) => form.setFieldsValue({ [key]: val })}
      options={props.options} />
  );
};

export const objectInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const {
    updatable, type, form, key, fieldValue, style,
    domainName, onValuesChange, options, zIndex,
    multiple, column,
  } = props;

  const convertDefaultValue = (): undefined | string | number | Array<number> => {
    if (multiple) {
      return fieldValue;
    }
    if (isObject(fieldValue)) {
      return fieldValue.id;
    }
    const intValue = parseInt(fieldValue);
    if (isNaN(intValue)) {
      return undefined;
    }
    return intValue;
  };

  if (column.isDynamicField || column.type === 'object' || isObjectType(column.type)) {
    const defaultValue = convertDefaultValue();
    const mode = multiple ? "multiple" : undefined;

    return (
      <ObjectSelect
        column={column}
        options={options}
        ownerClass={domainName}
        fieldName={key}
        updatable={updatable}
        domainName={column.elementDomain ?? type}
        placeholder={SearchPlaceholderText}
        notFoundContent={SearchNoDataFoundText}
        defaultValue={defaultValue}
        value={defaultValue}
        form={form}
        onValuesChange={onValuesChange}
        onSelect={(val: number) => form.setFieldsValue({ [key]: val })}
        zIndex={zIndex}
        mode={mode}
        style={style}
      />
    );
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultValue = fieldValue?.map((v: any) => v.id);
    form.setFieldValue(key, defaultValue);
    return (
      <ObjectSelect
        column={column}
        ownerClass={domainName}
        fieldName={key}
        updatable={updatable}
        domainName={
          column.elementDomain ??
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          column.elementType!}
        placeholder={SearchPlaceholderText}
        notFoundContent={SearchNoDataFoundText}
        defaultValue={defaultValue}
        mode="multiple"
        form={form}
        style={style}
        onValuesChange={onValuesChange}
        zIndex={zIndex}
      />);
  }
};

export const httpMethodInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { fieldValue, key, form, updatable, options } = props;
  return (<HttpMethodSelect
    style={props.style ?? EditableComponentDefaultStyle}
    value={fieldValue}
    updatable={updatable}
    onSelect={(val: string) => {
      form.setFieldsValue({ [key]: val });
    }}
    options={options}
  />);
};

export const rolesInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const {
    updatable, form, key, fieldValue, style,
    domainName, zIndex, column, type
  } = props;
  return (
    <ObjectSelect
      column={column}
      className={`roles ${getReadOnlyClass(updatable)}`}
      ownerClass={domainName}
      fieldName={key}
      updatable={updatable}
      domainName={getRawDomainName(type)}
      placeholder={SearchPlaceholderText}
      notFoundContent={SearchNoDataFoundText}
      defaultValue={fieldValue}
      value={fieldValue}
      mode="multiple"
      onChange={(val: string) => form.setFieldsValue({ [key]: val })}
      labelField="authority"
      form={form}
      style={style}
      zIndex={zIndex} />);
};

export const staticFieldInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable, fieldValue, key, form, record, options } = props;
  return (
    <StaticFieldSelect
      style={props.style ?? EditableComponentDefaultStyle}
      onChange={(val: string) => form.setFieldsValue({ [key]: val })}
      record={record}
      value={fieldValue}
      options={options}
      key={key}
      updatable={updatable} />
  );
};

export const seriesInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { fieldValue, key, form } = props;
  return (
    <RangedSeriesInput
      style={props.style ?? EditableComponentDefaultStyle}
      value={fieldValue}
      max={2.4}
      labelSuffix={'  mm'}
      valueSuffix={'%'}
      onChange={(val: string) => form.setFieldsValue({ [key]: val })} />
  );
};

export const arrayInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { key, column } = props;
  const { elementType } = column;
  return (elementType === FileFieldType) ? (
    (<FileOperator
      {...props}
      columnKey={key}
      multiple={true}
      column={column}
    />)
  ) : (<HasDetailFieldPlaceholderCell
    {...props}
    columnKey={key}
  />);
};

export const lineChartInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { fieldValue, column } = props;
  return (
    <LineChart
      value={fieldValue}
      column={column}
    />
  );
};

export const tableChartInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { fieldValue, column } = props;
  return (
    <TableChart
      data={fieldValue}
      column={column}
    />
  );
};

export const treeSelectInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { fieldValue, key, form, column, updatable } = props;
  const { key: columnKey } = column;
  return (
    <TreeSelect
      updatable={updatable}
      columnKey={columnKey}
      style={props.style}
      value={fieldValue}
      onChange={(val: string) => form.setFieldsValue({ [key]: val })} />
  );
};

export const idInputComponentRender = (): ReactElement => (<Input className="id" type="hidden" />);

export const fileOperatorComponentRender = (props: EditableControllerProps): ReactElement => {
  const { page } = props;

  const fileOperatorProps = {
    ...props,
    columnKey: props.key,
    multiple: props.column.multiple ?? false,
    column: props.column,
  };
  if (page === 'LIST') {
    return <FileOperatorCell {...fileOperatorProps}/>;
  } else {
    return <FileOperator {...fileOperatorProps}/>;
  }
};

export const imageOperatorComponentRender = (props: EditableControllerProps): ReactElement => {
  return (<SingleImage {...props} />);
};

export const hasDetailColumnEditComponentRender = (props: EditableControllerProps): ReactElement => {
  const { key } = props;
  return (<HasDetailFieldPlaceholderCell
    {...props}
    columnKey={key}
  />);
};

export const commentsComponentRender = (props: EditableControllerProps): ReactElement => {
  return (<CommentsIcon {...props} />);
};


export const iconInputComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable, fieldValue, zIndex, onValuesChange, form, key } = props;

  return (
    <IconSelect
      value={fieldValue === undefined ? undefined : String(fieldValue)}
      onChange={(value) => onValuesChange?.({[key]: value}, form?.getFieldsValue())}
      readonly={!updatable}
      zIndex={zIndex}
    />
  );
};


export const subTableComponentRender = (props: EditableControllerProps): ReactElement => {
  const {
    column, updatable, form, domainName, record,
    zIndex, saveOptions, onValuesChange
  } = props;

  return (
    <SubTableForm
      column={column}
      owner={record}
      editMode={updatable}
      ownerClass={domainName}
      zIndex={zIndex}
      form={form}
      saveOptions={saveOptions}
      isCurrentActiveTab={true}
      onValuesChange={onValuesChange}
    />
  );
};

export const valueSelectComponentRender = (props: EditableControllerProps): ReactElement => {
  const { updatable, form, key, column } = props;
  return (
    <ValueSelect
      style={props.style ?? EditableComponentDefaultStyle}
      updatable={updatable}
      placeholder={SearchPlaceholderText}
      notFoundContent={SearchNoDataFoundText}
      onSelect={(val) => form.setFieldsValue({ [key]: val })}
      options={column.options}
    />
  );
};

export const entityAttributesComponentRender = (props: EditableControllerProps): ReactElement => {
  const {
    updatable, form, column, fieldValue, record,
    zIndex, domainName, onValuesChange, saveOptions,
    path,
  } = props;
  return (
    <EntityAttributesComponentCell
      value={fieldValue as EntityAttributeValues}
      column={column}
      owner={record}
      ownerClass={domainName}
      zIndex={zIndex}
      editable={updatable}
      form={form}
      onValuesChange={onValuesChange}
      saveOptions={saveOptions}
      path={path}
    />
  );
};
