import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Empty } from "antd";
import {
  ApiErrorStatusType, ContinueOperate,
  GroupMetaProps,
  RecordProps,
  SaveOptionProps, SaveRecordProps,
  TableMetaProps,
  UpdateProps
} from "@props/RecordProps";
import {
  fetchCurrentValue,
  fetchDomainMeta,
  onFinishFailed,
  fetchFormFieldGroups,
  fetchFormIdAndExtInfoByType, populateDomainData
} from '@utils/FetchUtils';
import { FieldsToHideOnEditPage } from '@config/base';
import { DataCollectForm } from "../";
import { callSaveDomainWithCallback, getLastTrigger, setLastTrigger } from '@utils/SaveDomainUtils';
import { pureObjectIsEmpty } from "@utils/ObjectUtils";
import { LargeSpin } from '../../components';
import { humanReadableTitle, removePackagePart } from "@utils/StringUtils";
import { transformRecord } from '@kernel/DisplayComponentsMapping';
import { emptyMethod } from "@utils/Constants";
import { getAllOnSavedCallback, getFormFieldsValue, useCustomHookForm } from "@utils/FormUtils";

const UpdateComponent: React.FC<UpdateProps> = (props: UpdateProps) => {
  const {
    formId, domainName, id, callback, validationCallback, readonly, columnNameInOwnerClass,
    ownerClass, ownerId, triggerSave, continueOperate, columns: initColumns,
    hideDetailPanel, zIndex, hideHeaderTitle, hideHeader, displayMode, formType,
    queryParameters, showBottomSaveButton, page, fetchType, setCancelConfirmMessage,
  } = props;

  const [columns, setColumns] = useState<Array<TableMetaProps>>([] as TableMetaProps[]);
  const [updateFormGroups, setUpdateFormGroups] = useState<Array<GroupMetaProps>>();
  const [record, setRecord] = useState<RecordProps>();
  const form = useCustomHookForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [groupLoading, setGroupLoading] = useState<boolean>(true);
  /** 如果父组件没有传递 formId 过来，本组件会往后台请求获取 formId */
  const [internalFormId, setInternalFormId] = useState<number | undefined>(formId);
  const [errorStatusCode, setErrorStatusCode] = useState<ApiErrorStatusType>();
  const [previousId, setPreviousId] = useState<number>();
  const path = `/Update|${domainName}`;
  const saveOptions = useMemo<SaveOptionProps>(() => ({
    arrayColumnOptions: {},
    dynamicTemplateAttributesFieldOptions: {},
  }), []);
  const finishCallbackRef = useRef<() => void>(emptyMethod);
  const idChanged = (previousId !== id);
  const onSaveCallback = useCallback((res: {
    continueOperate: ContinueOperate;
    data?: SaveRecordProps;
    status: "fail" | "success";
  }) => {
    getAllOnSavedCallback(form).forEach(it => it(res));
    callback?.(res);
    const { data } = res;
    if (res.status === 'success' && data && 'id' in data) {
      const transformedRecord = transformRecord(columns, data as RecordProps);
      setRecord(transformedRecord);
      form.setFieldsValue(transformedRecord);
      console.info(
        `Domain ${domainName} has been saved, backend return ${JSON.stringify(data)}`
      );
    }
  }, [columns, callback, form, domainName]);

  finishCallbackRef.current = () => {
    const values = getFormFieldsValue(form);
    if (values != null && !pureObjectIsEmpty(values)) {
      callSaveDomainWithCallback({
        callback: onSaveCallback, values, continueOperate, domainName, columns, options: saveOptions
      });
    } else {
      console.error(`Form values is null when save ${domainName}(${id})`);
    }
  };

  useEffect(() => {
    if (page != 'master-detail' && page != 'detail-drawer') {
      const url = `/${formId}/${domainName}/${id}/${readonly ? 'show' : 'update'}`;
      window.history.pushState('', '', url);
    }
  }, [formId, domainName, id, readonly, page]);

  useEffect(() => {
    // 如果 Props 中传递的 formId 为空，则到后台去获取 formId 并保存在 state 中
    if (formId == null && internalFormId == null) {
      const formType = (id == null) ? "Create" : "Update";
      fetchFormIdAndExtInfoByType(domainName, formType)
        .then(json => {
          setInternalFormId(json?.id);
        });
    } else if (formId != null) {
      //如果 Props 中传递的 formId 不为空则直接设置 internalFormId
      setInternalFormId(formId);
    }
  }, [domainName, id, formId, internalFormId]);

  useEffect(() => {
    // 如果数据（record) 已经准备好，元数据也已经准备好
    // 1. formId 不为 -1 且字段分组信息已经获取到
    // 2. formId 为 -1, 表示使用默认的 domainMeta 来渲染表单，肯定没有 group
    // 则设置 loading 为 false
    const useDefaultForm = (internalFormId === -1);
    const groupInfoRetrieved = (!useDefaultForm && updateFormGroups != null);
    const dataIsReady = (record != null);
    if ((loading === true && dataIsReady) && (groupInfoRetrieved || useDefaultForm)) {
      setLoading(false);
    }
  }, [internalFormId, loading, record, updateFormGroups]);

  useEffect(() => {
    if (!idChanged || internalFormId == null) {
      return;
    }
    const fetchInitValue = async (
      domainName: string, domainMeta: Array<TableMetaProps>, id: number
    ): Promise<void> => {
      try {
        // setColumns(domainMeta);
        let record = await fetchCurrentValue(domainName, id, fetchType, formId);
        if (formId && domainMeta.some(meta => !!meta.transient)) {
          record = (await populateDomainData({
            formId,
            domainName,
            objects: [record],
          }))[0];
        }
        // 支持从 URL 中传递参数，用于初始化表单
        // 将从 queryParameters 中获取的参数合并到后台返回的 record 中
        if (queryParameters != null && Object.keys(queryParameters).length > 0) {
          Object.keys(queryParameters).forEach(key => {
            record[key] = queryParameters[key];
          });
        }
        const columnsWithDynamicFields = domainMeta.filter(meta => !meta.isDynamicTemplateAttributeField);
        if (record.extInfo?.dynamicFields) {
          const { dynamicFields } = record.extInfo;
          columnsWithDynamicFields.push(...dynamicFields);
        }
        const transformed = transformRecord(columnsWithDynamicFields, record);
        setColumns(columnsWithDynamicFields);
        setRecord(transformed);
        form.setFieldsValue(record);
        if (internalFormId != null) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fetchFormFieldGroups(domainName, internalFormId, id)
            .then(g => {
              setUpdateFormGroups((g?.length > 0) ? g : []);
            }).catch(e => {
            console.warn(`Failed to get form ${internalFormId} group: ${JSON.stringify(e)}`);
            setUpdateFormGroups([]);
          }).finally(() => setGroupLoading(false));
        } else {
          setGroupLoading(false);
        }
      } catch (error) {
        console.error(`Error get current value[${domainName}][${id}]for update: , ${error}`);
        setErrorStatusCode((error as { status: ApiErrorStatusType }).status);
      } finally {
        setLoading(false);
      }
    };

    if (record == null || idChanged) {
      setPreviousId(id);
      if (initColumns != null && idChanged) {
        fetchInitValue(domainName, initColumns, id);
      } else if (initColumns == null || idChanged) {
        fetchDomainMeta(domainName, internalFormId)
          .then((domainMeta: Array<TableMetaProps>) => {
            return domainMeta;
          }).then((domainMeta: Array<TableMetaProps>) => {
            fetchInitValue(domainName, domainMeta, id);
          }).catch(e => {
            console.warn(`Failed to get ${domainName} domain meta: ${JSON.stringify(e)}`);
          });
      }
    }
  }, [
    internalFormId, domainName, id, form, initColumns, loading, record, formId, columns,
    groupLoading, previousId, idChanged, updateFormGroups, queryParameters, fetchType
  ]);

  useEffect(() => {
    if (!!triggerSave && (readonly !== true) && getLastTrigger() !== triggerSave) {
      setLastTrigger(triggerSave);
      const values = form.getFieldsValue();
      setRecord(values);
      form.validateFields().then(finishCallbackRef.current);
    }
    // Only include triggerSave to avoid duplicate API call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSave]);

  if (errorStatusCode != null) {
    const domainTitle = `${humanReadableTitle(removePackagePart(domainName) ?? "")}`;
    const ErrorStatusToMsgMap: {
      [propName: number]: string;
    } = {
      403: `You don't have permission to view or edit ${domainTitle} with id [${id}]`,
      404: `${domainTitle} with id [${id}] does not exist`,
      500: `Error to getting data of ${domainTitle} with id [${id}]]`,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Empty style={{ paddingTop: "calc(.30 * 100vh)" }} description={ErrorStatusToMsgMap[errorStatusCode]} />;
  }
  const stillLoading = (loading || groupLoading);

  return (stillLoading) ?
    <LargeSpin /> : (
      <DataCollectForm
        hideHeaderTitle={hideHeaderTitle}
        columns={columns}
        groups={updateFormGroups ?? []}
        domainName={domainName}
        onFinish={finishCallbackRef.current}
        onFinishFailed={(e) => {
          onFinishFailed(e);
        }}
        onChange={emptyMethod}
        form={form}
        record={record}
        operation="update"
        ownerClass={ownerClass}
        columnNameInOwnerClass={columnNameInOwnerClass}
        ownerId={ownerId}
        validationCallback={validationCallback}
        hideFields={FieldsToHideOnEditPage}
        readonly={readonly}
        hideDetailPanel={hideDetailPanel}
        zIndex={zIndex}
        deleteCallback={() => {
          callback();
        }}
        updateCallback={() => {
          callback();
        }}
        hideHeader={hideHeader}
        displayMode={displayMode}
        formType={formType}
        showBottomSaveButton={showBottomSaveButton}
        setColumns={setColumns}
        saveOptions={saveOptions}
        page={page ?? "edit"}
        setCancelConfirmMessage={setCancelConfirmMessage}
        path={path}
      />);
};

export default UpdateComponent;
