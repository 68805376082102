import React, { ReactElement, useEffect, useState } from "react";
import { EnumMetaProps, SelectMetaProps } from "@props/RecordProps";
import { isDynamicSelectColumn, isEnumColumn } from "@utils/ColumnsUtils";
import { fetchSelectOptions } from "@utils/FetchUtils";
import { Select } from "antd";
import { SearchInputProps } from "@kernel/SearchInputComponents";

const { Option } = Select;

const EnumOrDynamicSelectSearchInput = (props: SearchInputProps): ReactElement => {
  const {
    commonProps, onUpdateSearchKeyword, type, enumValue, dynamicSelectValue, fieldName
  } = props;
  const [options, setOptions] = useState([] as (Array<EnumMetaProps | SelectMetaProps>));
  useEffect(() => {
    if (isEnumColumn(type ?? "")) {
      setOptions(enumValue ?? []);
    } else {
      if (dynamicSelectValue != null && dynamicSelectValue?.length > 0){
        setOptions(dynamicSelectValue);
      } else if (isDynamicSelectColumn(type ?? "")) {
        fieldName != null && fetchSelectOptions(fieldName).then((res: string[]) => {
          console.log(res);
          const opts = res.map((opt: string) => {
            return { value: opt, label: opt };
          });
          setOptions(opts);
        });
      }
    }
  }, [type, dynamicSelectValue, enumValue, fieldName]);

  return (
    <Select
      showSearch
      className="search-input"
      mode="multiple"
      defaultActiveFirstOption={true}
      showArrow={true}
      dropdownMatchSelectWidth={true}
      optionFilterProp="children"
      filterOption={(input, option) => {
        if (option == null || option.children == null) {
          return false;
        }
        return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={(value) => onUpdateSearchKeyword(value)}
      {...commonProps}
    >
      {options?.map((d: EnumMetaProps | SelectMetaProps) =>
        <Option key={d.value} value={d.value}>{d.label}</Option>)}
    </Select>
  );
};

export default EnumOrDynamicSelectSearchInput;
