import React, { CSSProperties, ReactElement } from "react";
import { FormInstance } from "antd";
import {
  CellComponentDisplayPage, ColumnType, EnumMetaProps, EnumTypes, MatchMode, ObjectValues,
  RecordProps, RecordValueType, DetailPanelProps, SaveRecordProps,
  Store, TableMetaProps, TableMode, SaveOptionProps
} from "@props/RecordProps";
import {
  arrayInputComponentRender,
  booleanInputComponentRender,
  checkboxInputComponentRender,
  codeInputComponentRender,
  currencyInputComponentRender,
  datetimeInputComponentRender,
  decimalInputComponentRender,
  enumInputComponentRender,
  httpMethodInputComponentRender,
  idInputComponentRender,
  integerInputComponentRender,
  lineChartInputComponentRender,
  objectInputComponentRender,
  passwordInputComponentRender,
  percentageInputComponentRender,
  radioInputComponentRender,
  rolesInputComponentRender,
  selectComponentRender,
  seriesInputComponentRender,
  staticFieldInputComponentRender,
  stringInputComponentRender,
  tagsInputComponentRender,
  textInputComponentRender,
  fileOperatorComponentRender,
  imageOperatorComponentRender,
  treeSelectInputComponentRender,
  commentsComponentRender,
  hasDetailColumnEditComponentRender,
  iconInputComponentRender,
  subTableComponentRender,
  valueSelectComponentRender,
  tagListInputComponentRender,
  entityAttributesComponentRender,
  tableChartInputComponentRender,
} from "./EditableComponents";
import {
  codeDisplayComponentRender,
  getEnumDisplayComponent,
  objectDisplayComponentRender,
  relateObjectCellComponentRender,
  tagsDisplayComponentRender,
  booleanDisplayComponentRender,
  multipleSelectDisplayComponentRender,
  dateDisplayComponentRender,
  httpMethodDisplayComponentRender,
  rolesDisplayComponentRender,
  radioDisplayComponentRender,
  percentageDisplayComponentRender,
  numericDisplayComponentRender,
  fieldDisplayComponentRender,
  updatedIdsDisplayComponentRender,
  stacktraceDisplayComponentRender,
  cronExpressionDisplayComponentRender,
  stringDisplayComponentRender,
  jsonDisplayComponentRender,
  markdownDisplayComponentRender,
  objectIdsDisplayComponentRender,
  attachmentDisplayComponentRender,
  documentDisplayComponentRender,
  imageDisplayComponentRender,
  ratingsDisplayComponentRender,
  commentsDisplayComponentRender,
  groupedGrandChildDisplayComponentRender,
  urlDisplayComponentRender,
  iconDisplayComponentRender,
  zonedDatetimeDisplayComponentRender,
  idCellComponentRender,
  entityAttributesDisplayComponentRender,
  tagListDisplayComponentRender,
  subTableDisplayComponentRender,
  relateObjectInlineCellComponentRender,
  lineChartDisplayComponentRender,
  tableChartDisplayComponentRender,
} from "./DisplayComponents";

import DocumentDetail from '../components/documentEditor/DocumentDetail';
import { isEnumColumn, isObjectType } from "@utils/ColumnsUtils";
import {
  ArrayMatchers, BooleanMatchers, ConfigAttributeMatchers, DateMatchers, MultipleSelectMatchers,
  NumberMatchers, ObjectMatchers, SingleSelectionMatchers, TextMatchers
} from "./ServerSideSearcherConfig";
import {
  transferAndFormatJson,
  transferMomentObjToLocalDateString,
  transferMomentObjToLocalDateTimeString,
  transferMomentObjToString,
  transferObjectIds,
  transferObjectOrObjects,
  transferStringToMomentObj,
  transferStringToTagList,
  transferSubTable,
  transferTagListToString,
  transferZonedDatetimeToMomentObj,
} from "./ValueConverters";
import {
  getBooleanSearchInput,
  getCurrencySearchInput,
  getDateSearchInput,
  getEnumOrDynamicSelectSearchInput,
  getHttpMethodSelectSearchInput,
  getNumberSearchInput,
  getObjectSearchInput,
  getPercentageSearchInput,
  SearchInputProps
} from "@kernel/SearchInputComponents";
import {
  CommentStatusEnumType,
  EquipmentStateEnumType,
  EquipmentStatusEnumType,
  ExecuteStatusEnumType,
  FileImportStatusEnumType,
  MessageSeverityEnumType,
  TaskScheduleTypeEnumType,
  TaskTriggerMethodEnumType
} from "@config/domain";
import { RelateObjects, UpdatedIdsDetail } from "../form/cells";
import { CodeEditorDetail } from "../form/fields";
import { GroupedGrandChildDetailPanel } from "../components/groupedGrandChild";
import { SubTableDetailPanel } from "../form/subTable/SubTableDetailPanel";
import { EntityAttributesDetailPanel } from "../form/entityAttributes/EntityAttributesDetailPanel";
import { FunctionEditorDetail } from "../components/functionEditor/FunctionEditorDetail";
import { TableChartDetailPanel } from "../form/fields/TableChartDetail";
import LineChartDetailPanel from "../form/fields/LineChartDetail";

export type EditableComponentRenderFunction = (props: EditableControllerProps) => ReactElement;
export type DisplayComponentRenderFunction = (text: unknown, record: RecordProps) => ReactElement;
export type DetailComponentRenderFunction = (props: DetailPanelProps) => ReactElement;
export type SearchConditionInputComponentRenderFunction = (props: SearchInputProps) => ReactElement;

const emptyEditableComponentRenderFunction = (): ReactElement => (<></>);

/**
 * 获取进行组件配置查找的 type
 */
export const getDestTypeForComponentLookup = (type: ColumnType): ColumnType => {
  const isStatusField = isStatusSeverityType(type);
  // 判断 !FieldConfigures.map(c => c.type).includes(type) 是为了支持 Object 类型自定义显示控件
  return (isObjectType(type) && !FieldConfigures.map(c => c.type).includes(type)) ? "object" :
    (isEnumColumn(type)) ? (isStatusField ? type : "enum") : type;
};

const getStatusSeverityComponentConfigs = (type: EnumTypes): FieldConfigProps => {
  return {
    type,
    valuePropName: "value",
    editableComponent: () => enumInputComponentRender,
    displayComponent: getEnumDisplayComponent(type),
    searchMatchers: SingleSelectionMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  };
};

export type EditableControllerProps = {
  updatable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldValue: string | undefined | any;
  type: string;
  fieldTitle: string | ReactElement;
  form: FormInstance;
  key: string;
  // decides?: Array<string>;
  options?: Array<EnumMetaProps> | undefined;
  domainName: string;
  record: SaveRecordProps | undefined;
  onValuesChange?: (changedValues: Store, allValues: Store) => void;
  switchTabCallback?: (key: string) => void;
  isHighlightField?: boolean;
  zIndex: number;
  multiple: boolean;
  column: TableMetaProps;
  style?: CSSProperties;
  saveOptions?: SaveOptionProps;
  path?: string;
  page?: CellComponentDisplayPage;
};

export type DisplayControllerProps = {
  column: TableMetaProps;
  enumValues: { [id: string]: EnumMetaProps[] };
  objectValues: ObjectValues;
  domainName: string;
  page: CellComponentDisplayPage;
  zIndex: number;
  switchTabCallback?: (key: string) => void;
  isHighlightField?: boolean;
  tableMode?: TableMode;
  multiple: boolean;
  form?: FormInstance;
};

export const isStatusSeverityType = (type: string): boolean => {
  //FIXME 这里的特殊判断修改为通用的方式，这两个种类型的字段在后台返回特定的 type, 然后前台做匹配
  return ([
    FileImportStatusEnumType, ExecuteStatusEnumType, MessageSeverityEnumType,
    TaskTriggerMethodEnumType, TaskScheduleTypeEnumType, EquipmentStatusEnumType,
    CommentStatusEnumType, EquipmentStateEnumType
  ]
    .includes(type));
};

/** 字段配置 */
export interface FieldConfigProps {

  /** 类型，用于从配置表中查找对应的配置 */
  type: ColumnType;

  /** 保存显示模式，包括列表和详情显示页面的渲染函数 */
  displayComponent: (props: DisplayControllerProps) => DisplayComponentRenderFunction;

  /** 保存编辑时，Form 从该组件的哪个属性中，获取用户输入值 */
  valuePropName?: string;

  /** 保存编辑模式下的渲染函数 */
  editableComponent?: (tableMode?: TableMode) => EditableComponentRenderFunction;
  inlineEditableComponent?: (tableMode?: TableMode) => EditableComponentRenderFunction;

  /** 保存 detail 模式下的渲染函数 */
  detailComponent?: DetailComponentRenderFunction;

  /** 保存搜索时候可用的匹配模式 */
  searchMatchers?: Array<MatchMode>;

  /*
   * 保存从后台返回的记录中的值，在传递给控件显示前，
   * 应该通过什么样的转换函数进行转换，然后再显示
   */
  backendValueConverter?: (record: RecordProps, value: RecordValueType) => RecordValueType;

  frontendValueConverter?: (record: SaveRecordProps, value: RecordValueType) => RecordValueType;

  /**
   * 点击表头上的 🔍 图标出来的搜索面板中的搜索条件输入框控件
   */
  searchConditionInputComponent?: SearchConditionInputComponentRenderFunction;
}


export const FieldConfigures: Array<FieldConfigProps> = [
  {
    type: "id",
    valuePropName: "value",
    editableComponent: () => idInputComponentRender,
    displayComponent: idCellComponentRender,
    searchMatchers: NumberMatchers,
    searchConditionInputComponent: getNumberSearchInput,
  }, {
    type: "int",
    valuePropName: "value",
    editableComponent: () => integerInputComponentRender,
    displayComponent: numericDisplayComponentRender,
    searchMatchers: NumberMatchers,
    searchConditionInputComponent: getNumberSearchInput,
  }, {
    type: "integer",
    valuePropName: "value",
    editableComponent: () => integerInputComponentRender,
    displayComponent: numericDisplayComponentRender,
    searchMatchers: NumberMatchers,
    searchConditionInputComponent: getNumberSearchInput,
  }, {
    type: "long",
    valuePropName: "value",
    editableComponent: () => integerInputComponentRender,
    displayComponent: numericDisplayComponentRender,
    searchMatchers: NumberMatchers,
    searchConditionInputComponent: getNumberSearchInput,
  }, {
    type: "decimal",
    valuePropName: "value",
    editableComponent: () => decimalInputComponentRender,
    displayComponent: numericDisplayComponentRender,
    searchMatchers: NumberMatchers,
    searchConditionInputComponent: getNumberSearchInput,
  }, {
    type: "string",
    valuePropName: "value",
    editableComponent: () => stringInputComponentRender,
    displayComponent: stringDisplayComponentRender,
    searchMatchers: TextMatchers,
  }, {
    type: "cronExpression",
    valuePropName: "value",
    editableComponent: () => stringInputComponentRender,
    displayComponent: cronExpressionDisplayComponentRender,
    searchMatchers: TextMatchers,
  }, {
    type: "password",
    valuePropName: "value",
    editableComponent: () => passwordInputComponentRender,
    displayComponent: stringDisplayComponentRender,
    searchMatchers: TextMatchers,
  }, {
    type: "datetime",
    valuePropName: "value",
    editableComponent: () => datetimeInputComponentRender(true),
    displayComponent: dateDisplayComponentRender,
    searchMatchers: DateMatchers,
    backendValueConverter: transferStringToMomentObj,
    frontendValueConverter: transferMomentObjToLocalDateTimeString,
    searchConditionInputComponent: getDateSearchInput,
  }, {
    type: "zonedDatetime",
    valuePropName: "value",
    editableComponent: () => datetimeInputComponentRender(true),
    displayComponent: zonedDatetimeDisplayComponentRender,
    searchMatchers: DateMatchers,
    backendValueConverter: transferZonedDatetimeToMomentObj,
    frontendValueConverter: transferMomentObjToString,
    searchConditionInputComponent: getDateSearchInput,
  }, {
    type: "date",
    valuePropName: "value",
    editableComponent: () => datetimeInputComponentRender(false),
    displayComponent: dateDisplayComponentRender,
    searchMatchers: DateMatchers,
    backendValueConverter: transferStringToMomentObj,
    frontendValueConverter: transferMomentObjToLocalDateString,
    searchConditionInputComponent: getDateSearchInput,
  }, {
    type: "boolean",
    valuePropName: "checked",
    editableComponent: () => booleanInputComponentRender,
    displayComponent: booleanDisplayComponentRender,
    searchMatchers: BooleanMatchers,
    searchConditionInputComponent: getBooleanSearchInput,
  }, {
    type: "text",
    valuePropName: "value",
    editableComponent: (tableMode?: TableMode) => textInputComponentRender(tableMode),
    displayComponent: stringDisplayComponentRender,
    searchMatchers: TextMatchers,
  }, {
    type: "percentage",
    valuePropName: "value",
    editableComponent: () => percentageInputComponentRender,
    displayComponent: percentageDisplayComponentRender,
    searchMatchers: NumberMatchers,
    searchConditionInputComponent: getPercentageSearchInput,
  }, {
    type: "currency",
    valuePropName: "value",
    editableComponent: () => currencyInputComponentRender,
    displayComponent: stringDisplayComponentRender,
    searchMatchers: NumberMatchers,
    searchConditionInputComponent: getCurrencySearchInput,
  }, {
    type: "tags",
    valuePropName: "value",
    editableComponent: () => tagsInputComponentRender,
    displayComponent: tagsDisplayComponentRender,
    searchMatchers: MultipleSelectMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  }, {
    type: "tag_list",
    valuePropName: "value",
    editableComponent: () => tagListInputComponentRender,
    displayComponent: tagListDisplayComponentRender,
    backendValueConverter: transferStringToTagList,
    frontendValueConverter: transferTagListToString,
    searchMatchers: MultipleSelectMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  }, {
    type: "df_tags",
    valuePropName: "value",
    editableComponent: () => selectComponentRender("tags"),
    displayComponent: multipleSelectDisplayComponentRender,
    searchMatchers: MultipleSelectMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  }, {
    type: "multiple",
    valuePropName: "value",
    editableComponent: () => selectComponentRender("multiple"),
    displayComponent: multipleSelectDisplayComponentRender,
    searchMatchers: MultipleSelectMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  }, {
    type: "df_multiple",
    valuePropName: "value",
    editableComponent: () => selectComponentRender("multiple"),
    displayComponent: multipleSelectDisplayComponentRender,
    searchMatchers: MultipleSelectMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  }, {
    type: "df_single",
    valuePropName: "value",
    editableComponent: () => selectComponentRender(),
    displayComponent: stringDisplayComponentRender,
    searchMatchers: SingleSelectionMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  }, {
    type: "df_radio",
    valuePropName: "value",
    editableComponent: () => radioInputComponentRender,
    displayComponent: radioDisplayComponentRender,
    searchMatchers: SingleSelectionMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  }, {
    type: "df_checkbox",
    valuePropName: "value",
    editableComponent: () => checkboxInputComponentRender,
    displayComponent: multipleSelectDisplayComponentRender,
    searchMatchers: SingleSelectionMatchers,
    searchConditionInputComponent: getEnumOrDynamicSelectSearchInput,
  },
  getStatusSeverityComponentConfigs(MessageSeverityEnumType),
  getStatusSeverityComponentConfigs(ExecuteStatusEnumType),
  getStatusSeverityComponentConfigs(FileImportStatusEnumType),
  getStatusSeverityComponentConfigs(TaskTriggerMethodEnumType),
  getStatusSeverityComponentConfigs(TaskScheduleTypeEnumType),
  getStatusSeverityComponentConfigs(EquipmentStatusEnumType),
  getStatusSeverityComponentConfigs(CommentStatusEnumType),
  getStatusSeverityComponentConfigs(EquipmentStateEnumType),
  getStatusSeverityComponentConfigs("enum"),
  {//对象的多选字段
    type: "object_multiple",
    valuePropName: "value",
    editableComponent: () => objectInputComponentRender,
    displayComponent: objectDisplayComponentRender,
    searchMatchers: MultipleSelectMatchers,
    backendValueConverter: transferObjectOrObjects,
    searchConditionInputComponent: getObjectSearchInput,
  }, {
    type: "object",
    valuePropName: "value",
    editableComponent: () => objectInputComponentRender,
    displayComponent: objectDisplayComponentRender,
    searchMatchers: ObjectMatchers,
    backendValueConverter: transferObjectOrObjects,
    searchConditionInputComponent: getObjectSearchInput,
  }, {
    type: "code",
    valuePropName: "value",
    editableComponent: (tableMode?: TableMode) => codeInputComponentRender(tableMode),
    displayComponent: codeDisplayComponentRender,
    detailComponent: CodeEditorDetail,
    searchMatchers: TextMatchers,
  }, {
    type: "httpMethod",
    valuePropName: "value",
    editableComponent: () => httpMethodInputComponentRender,
    displayComponent: httpMethodDisplayComponentRender,
    searchConditionInputComponent: getHttpMethodSelectSearchInput,
    searchMatchers: ObjectMatchers,
  }, {
    type: "roles",
    valuePropName: "value",
    editableComponent: () => rolesInputComponentRender,
    displayComponent: rolesDisplayComponentRender,
    searchMatchers: ConfigAttributeMatchers,
    searchConditionInputComponent: getObjectSearchInput,
  }, {
    type: "staticField",
    valuePropName: "value",
    editableComponent: () => staticFieldInputComponentRender,
    displayComponent: fieldDisplayComponentRender,
    searchMatchers: TextMatchers,
  }, {
    type: "series",
    valuePropName: "value",
    editableComponent: () => seriesInputComponentRender,
    displayComponent: stringDisplayComponentRender,
  }, {
    type: "array",
    valuePropName: "value",
    editableComponent: () => arrayInputComponentRender,
    displayComponent: relateObjectCellComponentRender,
    detailComponent: RelateObjects,
    searchMatchers: ArrayMatchers,
  }, {
    type: "arrayInline",
    valuePropName: "value",
    displayComponent: relateObjectInlineCellComponentRender,
    searchMatchers: ArrayMatchers,
  }, {
    type: "arrayDetail",
    valuePropName: "value",
    editableComponent: () => arrayInputComponentRender,
    displayComponent: relateObjectCellComponentRender,
    detailComponent: RelateObjects,
    searchMatchers: ArrayMatchers,
  }, {
    type: "tech_muyan_enterprisewiki_contract_Statistic",
    valuePropName: "value",
    editableComponent: () => emptyEditableComponentRenderFunction,
    displayComponent: ratingsDisplayComponentRender,
  }, {
    type: "lineChart",
    valuePropName: "value",
    editableComponent: () => lineChartInputComponentRender,
    detailComponent: LineChartDetailPanel,
    displayComponent: lineChartDisplayComponentRender,
  }, {
    type: "tableChart",
    editableComponent: () => tableChartInputComponentRender,
    displayComponent: tableChartDisplayComponentRender,
    detailComponent: TableChartDetailPanel,
  }, {
    type: "treeSelect",
    valuePropName: "value",
    editableComponent: () => treeSelectInputComponentRender,
    displayComponent: stringDisplayComponentRender,
  }, {
    type: "updatedIds",
    displayComponent: updatedIdsDisplayComponentRender,
    detailComponent: UpdatedIdsDetail,
    searchMatchers: TextMatchers,
  }, {
    type: "stacktrace",
    displayComponent: stacktraceDisplayComponentRender,
    searchMatchers: TextMatchers,
  }, {
    type: "json",
    displayComponent: jsonDisplayComponentRender,
    editableComponent: (tableMode?: TableMode) => codeInputComponentRender(tableMode),
    detailComponent: CodeEditorDetail,
    backendValueConverter: transferAndFormatJson,
    searchMatchers: TextMatchers,
  }, {
    type: "markdown",
    displayComponent: markdownDisplayComponentRender,
    editableComponent: (tableMode?: TableMode) => codeInputComponentRender(tableMode),
    detailComponent: CodeEditorDetail,
    searchMatchers: TextMatchers,
  }, {
    type: "objectIds",
    displayComponent: objectIdsDisplayComponentRender,
    editableComponent: () => tagsInputComponentRender,
    backendValueConverter: transferObjectIds,
    searchMatchers: TextMatchers,
  }, {
    type: "file",
    displayComponent: attachmentDisplayComponentRender,
    editableComponent: () => fileOperatorComponentRender,
    searchMatchers: ArrayMatchers,
  }, {
    type: "document",
    displayComponent: documentDisplayComponentRender,
    editableComponent: () => hasDetailColumnEditComponentRender,
    detailComponent: DocumentDetail,
    searchMatchers: TextMatchers,
  }, {
    type: "image",
    displayComponent: imageDisplayComponentRender,
    editableComponent: () => imageOperatorComponentRender,
    searchMatchers: ArrayMatchers,
  }, {
    type: "comments",
    displayComponent: commentsDisplayComponentRender,
    editableComponent: () => commentsComponentRender,
    searchMatchers: ArrayMatchers
  }, {
    type: "groupedGrandChild",
    displayComponent: groupedGrandChildDisplayComponentRender,
    editableComponent: () => hasDetailColumnEditComponentRender,
    detailComponent: GroupedGrandChildDetailPanel,
    searchMatchers: ArrayMatchers,
  }, {
    type: "url",
    displayComponent: urlDisplayComponentRender,
    editableComponent: () => stringInputComponentRender,
    searchMatchers: TextMatchers,
  }, {
    type: "icon",
    displayComponent: iconDisplayComponentRender,
    editableComponent: () => iconInputComponentRender,
    searchMatchers: TextMatchers,
  }, {
    type: "subTable",
    displayComponent: subTableDisplayComponentRender,
    editableComponent: () => subTableComponentRender,
    detailComponent: SubTableDetailPanel,
    backendValueConverter: transferSubTable,
  }, {
    type: "entityAttributes",
    displayComponent: entityAttributesDisplayComponentRender,
    editableComponent: () => entityAttributesComponentRender,
    detailComponent: EntityAttributesDetailPanel,
  }, {
    type: "valueSelect",
    displayComponent: stringDisplayComponentRender,
    editableComponent: () => valueSelectComponentRender,
  }, {
    type: "functionEditor",
    valuePropName: "value",
    editableComponent: (tableMode?: TableMode) => codeInputComponentRender(tableMode),
    displayComponent: codeDisplayComponentRender,
    detailComponent: FunctionEditorDetail,
    searchMatchers: TextMatchers,
  }
];
